<template>
  <div class="inbox" id="inbox">
    <template @click="hideFilterbar">
      <!-- Old top nav menu that is shown on mobile layout only -->
      <div class="topbar navbar-mobile flex-between d-md-none">
        <a class="topbar-menu flex-middle-center" @click="showFilterbar(true)">
          <i class="fas fa-bars"></i>
        </a>
        <div
          class="topbar-info flex-middle-center w-100 h-100"
          @click="showFilterbar(true)"
        >
          Inbox
        </div>
      </div>

      <!-- Top panel on the right (inflencer email address + sender email address + New message button) -->
      <div class="email-selector row" v-if="lead">
        <div
          class="col-12 d-flex justify-content-end align-items-center flex-wrap"
        >
          <div
            v-if="allLeadEmails && allLeadEmails.length"
            class="flex-middle mx-2"
          >
            <label class="mr-3 mb-0">Lead email</label>
            <base-dropdown class="nav-item" position="left">
              <a
                slot="title"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="
                  cursor-pointer
                  flex-middle-center
                  text-overflow-ellipsis
                  py-2
                "
              >
                {{ leadEmail }}<i class="fas fa-angle-down ml-2"></i>
              </a>
              <li
                v-for="(emailAddress, index) in allLeadEmails"
                :key="index"
                class="dropdown-item cursor-pointer px-3 py-3"
                @click="leadEmail = emailAddress"
              >
                {{ emailAddress }}
              </li>
            </base-dropdown>
          </div>
          <div class="flex-middle mx-2">
            <label class="mr-3 mb-0">Your email</label>

            <base-dropdown class="nav-item" position="right">
              <a
                slot="title"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="
                  cursor-pointer
                  flex-middle-center
                  text-overflow-ellipsis
                  py-2
                "
              >
                <img
                  v-if="
                    threadsCurrentEmailAccount &&
                    threadsCurrentEmailAccount.type
                  "
                  class="icon-size16 mr-2"
                  :src="availableEmails[threadsCurrentEmailAccount.type].image"
                  :alt="availableEmails[threadsCurrentEmailAccount.type].name"
                />
                {{
                  threadsCurrentEmailAccount &&
                  threadsCurrentEmailAccount.email
                }}<i class="fas fa-angle-down ml-2"></i>
              </a>
              <template v-if="allEmailAccounts.length">
                <li
                  v-for="(item, id) in allEmailAccounts"
                  :key="id"
                  class="dropdown-item cursor-pointer px-3 py-3"
                  @click="changeUserEmailNew(item)"
                >
                  <span v-if="item.email == 'Any'">
                    {{ item.email }}
                  </span>
                  <span v-else>
                    <img
                      class="icon-size16 mr-2"
                      :src="availableEmails[item.type].image"
                      :alt="availableEmails[item.type].name"
                    />
                    {{ item.email }}
                  </span>
                </li>
              </template>
            </base-dropdown>
          </div>
          <!-- v-if="userEmail && leadEmail && threadId" -->
          <template>
            <base-button
              :disabled="!hasFullAccess('emails')"
              :disabledTooltip="getPermissionErrorMessage('emails:full')"
              v-b-tooltip.hover.bottom
              title="Start a new conversation with a selected lead"
              type="primary"
              class="btn-black mx-3 my-2"
              @click="startSending(null)"
            >
              New message
            </base-button>
          </template>
        </div>
      </div>

      <!-- Area for displaying threads + emails content + composing messages -->
      <div ref="emailContent" class="email-content">
        <rs-panes
          split-to="columns"
          :allow-resize="allowResize"
          :size="firstPaneWidth"
          resizerColor="#e0e0e0"
          :resizerBorderThickness="1"
        >
          <!-- Panel for threads -->
          <div slot="firstPane" class="split-pane">
            <div class="thread-container">
              <ul class="thread-list" v-if="emailThreads.length">
                <li
                  v-for="(thread, index) in emailThreads"
                  :key="index"
                  @click="selectThread(thread)"
                >
                  <card
                    class="thread"
                    :class="
                      (thread.id == threadId ? 'selected' : '') +
                      (hasUnread(thread) ? ' unread' : '')
                    "
                  >
                    <div class="flex-between">
                      <div class="email-header text-truncate">
                        <h3 class="text-truncate">
                          {{ thread.fromName || thread.fromAddress }}
                        </h3>
                        <div>
                          {{ thread.lastMessageSubject }}
                        </div>
                      </div>
                      <div class="email-date">
                        {{ thread.updatedDateShort }}
                      </div>
                    </div>
                    <div class="email-preview">
                      {{ thread.bodyPreview | StripTags }}
                    </div>
                  </card>
                </li>
              </ul>
              <div class="threads-empty" v-else>
                You don't have any conversations with this lead yet.
              </div>
            </div>
            <div
              v-if="isShallow && !isFirstPaneFocused"
              class="pane-overlay"
              @click="focusPane(true)"
            >
              <span style="transform: rotate(-90deg)">Threads</span>
            </div>
          </div>
          <!-- Panel for messages (conversation) of the selected thread -->
          <div slot="secondPane" class="split-pane">
            <div class="conversation-container">
              <!-- Conversation header -->
              <div v-if="selectedThread" class="conversation-header">
                {{ selectedThread.subject || "Subject is not presented" }}
              </div>
              <!-- Conversation messages list -->
              <div v-if="selectedThread" class="conversation-list">
                <div
                  v-for="(emailMessage, index) in selectedThread.emailMessages"
                  :key="index"
                  class="card mb-2 cursor-pointer"
                  :class="emailMessage.unread ? 'unread' : ''"
                >
                  <div
                    v-if="!collapse[index]"
                    class="card-body flex-middle p-2"
                    @click="toggleCollapse(index)"
                  >
                    <!-- Collapsed message -->
                    <div class="message-collapsed">
                      <div class="message-top-part">
                        <div class="email-avatar">
                          <avatar
                            :username="
                              emailMessage.from
                                ? emailMessage.from.name
                                : emailMessage.from_name
                            "
                            :size="40"
                            :src="
                              getImageByEmailAddress(
                                emailMessage.from
                                  ? emailMessage.from.address
                                  : emailMessage.from_address
                              )
                            "
                          >
                          </avatar>
                        </div>
                        <div class="email-name">
                          <div class="text-truncate">
                            {{
                              emailMessage.from
                                ? emailMessage.from.name
                                : emailMessage.from_name
                            }}
                          </div>
                        </div>
                        <div class="email-date-time">
                          <div class="email">
                            {{
                              emailMessage.updated_at ||
                              emailMessage.sent_at | ShortDate
                            }}
                          </div>
                          <div>
                            {{
                              emailMessage.updated_at ||
                              emailMessage.sent_at | ShortTime
                            }}
                          </div>
                        </div>
                        <div class="email-action">
                          <div
                            class="btn-group mr-1"
                            role="group"
                            aria-label="Email action"
                          >
                            <base-button
                              v-if="emailMessage.weblink"
                              v-b-tooltip.hover
                              title="View in your inbox"
                              type="primary"
                              icon="fas fa-eye"
                              class=""
                              @click="openInbox(index)"
                            />

                            <base-button
                              v-if="showReply(emailMessage)"
                              :disabled="!hasFullAccess('emails')"
                              :disabledTooltip="
                                getPermissionErrorMessage('emails:full')
                              "
                              title="Reply"
                              type="primary"
                              icon="fas fa-reply"
                              @click="startSending(emailMessage)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="message-bottom-part">
                        <div class="email-preview text-truncate">
                          {{
                            emailMessage.body_preview ||
                            emailMessage.body | Truncate100 | StripTags
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="card-body p-2">
                    <!-- Expanded message -->
                    <div
                      class="message-expanded"
                      @click="toggleCollapse(index, $event)"
                    >
                      <div class="message-top-part">
                        <div class="email-avatar">
                          <avatar
                            :username="
                              emailMessage.from
                                ? emailMessage.from.name
                                : emailMessage.from_name
                            "
                            :size="40"
                            :src="
                              getImageByEmailAddress(
                                emailMessage.from
                                  ? emailMessage.from.address
                                  : emailMessage.from_address
                              )
                            "
                          >
                          </avatar>
                        </div>
                        <div class="email-info">
                          <div class="email-info-item">
                            <div class="item-label">From:</div>
                            <div class="item-value">
                              {{
                                emailMessage.from
                                  ? emailMessage.from.name
                                  : emailMessage.from_name
                              }}
                              <span class="email-address--fade"
                                >&lt;{{
                                  emailMessage.from
                                    ? emailMessage.from.address
                                    : emailMessage.from_address
                                }}&gt;</span
                              >
                            </div>
                          </div>
                          <div class="email-info-item">
                            <div class="item-label">To:</div>
                            <div class="item-value">
                              <span
                                v-for="(
                                  receiver, rIndex
                                ) in emailMessage.to_recipients"
                                :key="rIndex"
                                >{{ (rIndex ? ", " : "") + receiver.name }}
                                <span
                                  :key="receiver.address"
                                  class="email-address--fade"
                                >
                                  &lt;{{ receiver.address }}&gt;</span
                                >
                              </span>
                            </div>
                          </div>
                          <div class="email-info-item">
                            <div class="item-label">Date:</div>
                            <div class="item-value">
                              {{
                                emailMessage.updated_at ||
                                emailMessage.sent_at | ShortDateTime
                              }}
                            </div>
                          </div>
                          <div
                            class="email-info-item"
                            v-if="
                              emailMessage.cc_recipients &&
                              emailMessage.cc_recipients.length
                            "
                          >
                            <div class="item-label">CC:</div>
                            <div class="item-value">
                              <template
                                v-for="(
                                  receiver, rIndex
                                ) in emailMessage.cc_recipients"
                              >
                                <strong :key="rIndex">{{
                                  receiver.name
                                }}</strong>
                                <span
                                  :key="receiver.address"
                                  class="email-address--fade"
                                >
                                  &lt;{{ receiver.address }}&gt;</span
                                >
                              </template>
                            </div>
                          </div>
                          <div class="email-info-item">
                            <div class="item-label">Subject:</div>
                            <div class="item-value">
                              {{ emailMessage.subject }}
                            </div>
                          </div>
                          <div class="email-info-item">
                            <div class="item-label"></div>
                            <div class="item-value"></div>
                          </div>
                          <div class="email-info-item">
                            <div class="item-label"></div>
                            <div class="item-value"></div>
                          </div>
                        </div>
                      </div>

                      <div class="message-bottom-part">
                        <VueDocPreview
                          :value="removeImage(index)"
                          type="code"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Conversation editor for replies -->
              <div class="conversation-editor w-100">
                <template v-if="isReply">
                  <label>Reply </label>
                  <label v-if="replyToMessage">
                    (to message from
                    {{
                      replyToMessage.updated_at ||
                      replyToMessage.sent_at | ShortDateTime
                    }})</label
                  >
                </template>
                <label v-else>New conversation:</label>
                <div v-show="!isReply" class="email-factors mb-3">
                  <div class="flex-middle">
                    <label class="mr-2 mb-0">From</label>
                    <multiselect
                      v-if="fromOptions"
                      placeholder="Select your email"
                      v-model="userEmail"
                      label="address"
                      :allow-empty="false"
                      :hide-selected="true"
                      :disabled="false"
                      track-by="address"
                      :multiple="false"
                      :options="fromOptions"
                    >
                      <template slot="singleLabel" slot-scope="props"
                        ><img
                          class="icon-size16 mr-2"
                          :src="availableEmails[props.option.name].image"
                          :alt="availableEmails[props.option.name].name"
                        /><span class="option__desc"
                          ><span class="option__title">{{
                            props.option.address
                          }}</span></span
                        ></template
                      >
                      <template slot="option" slot-scope="props">
                        <div class="d-flex">
                          <img
                            class="icon-size16 mr-2"
                            :src="availableEmails[props.option.name].image"
                            :alt="availableEmails[props.option.name].name"
                          />
                          <div class="option__desc">
                            <span class="option__title">{{
                              props.option.address
                            }}</span>
                          </div>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                  <div class="mt-2 flex-middle tags">
                    <label class="mr-2 mb-0">To</label>
                    <multiselect
                      v-if="lead"
                      placeholder="Input influencer email(s)"
                      v-model="to"
                      :multiple="true"
                      :taggable="true"
                      track-by="address"
                      label="address"
                      :options="toOptions"
                      :disabled="isReply"
                      @tag="addTOOption"
                      @remove="refreshCCoptions"
                      @select="selectToEmail"
                      tagPlaceholder="Press enter to select"
                    />
                  </div>
                  <div class="mt-2 flex-middle tags">
                    <label class="mr-2 mb-0">Cc</label>
                    <multiselect
                      v-if="lead"
                      placeholder="Input Cc email(s)"
                      v-model="cc"
                      :multiple="true"
                      :taggable="true"
                      track-by="address"
                      label="address"
                      :options="ccOptions"
                      @tag="addCCOption"
                      tagPlaceholder="Press enter to select"
                    />
                  </div>
                  <div class="mt-2 flex-middle">
                    <label class="mr-2 mb-0">Subject</label>
                    <base-input
                      class="mb-0 w-100"
                      v-model="subject"
                      placeholder="No subject"
                    ></base-input>
                  </div>
                </div>

                <quill-editor
                  ref="threadQuillEditor"
                  v-model="content"
                ></quill-editor>
                <div class="flex-center">
                  <base-button
                    :disabled="!hasFullAccess('emails') || !userEmail"
                    :disabledTooltip="getPermissionErrorMessage('emails:full')"
                    :title="
                      isThreadSelected
                        ? 'Reply to continue the conversation'
                        : 'Start a new conversation!'
                    "
                    type="primary"
                    class="btn-black mt-2"
                    :isBusy="loading.send"
                    @click="sendDirectly()"
                  >
                    {{ isThreadSelected ? "Reply" : "Send" }}
                  </base-button>
                </div>
              </div>
            </div>
            <div
              v-if="isShallow && isFirstPaneFocused"
              class="pane-overlay"
              @click="focusPane(false)"
            >
              <span style="transform: rotate(90deg)">Conversation</span>
            </div>
          </div>
        </rs-panes>
        <div v-if="!leadEmail" class="inbox__overlay" @click="hideFilterbar">
          <div
            v-if="loading.userEmails || loading.messages"
            class="w-100 flex-middle-center"
          >
            <base-spinner></base-spinner>
            <span class="ml-1"> Loading... </span>
          </div>
          <template v-else-if="!showLeftNav">
            <p
              v-if="!allEmailAccounts.length"
              class="px-3 py-4 w-100 text-center"
            >
              <router-link to="/app/settings/email">Connect</router-link> your
              email address to manage your outreach from
              <strong>Ninja Influence</strong>.
            </p>
            <p v-else-if="lead" class="py-4 w-100 text-center">
              This influencer has no emails. You can add them manually by
              editing the influencer data.
            </p>
            <p v-else class="py-4 w-100 text-center">
              Please select an influencer from the left sidebar.
            </p>
          </template>
        </div>
      </div>
    </template>

    <!-- Left side with filters (Campaign + Status + HasEmailAddress) and influencers list -->
    <!-- Sidebar component that hosts list and filters -->
    <filter-bar :background-color="filterbarBackground">
      <div slot="filters">
        <!-- Filters and list component -->
        <InboxFilter :initialQuery="initialQuery" />
      </div>
    </filter-bar>
    <a
      v-if="$filterbar.showFilterbar"
      @click="toggleLeftNavbar"
      class="navbar-switch flex-middle-center d-md-none"
    >
      <span :class="showLeftNav ? 'active' : ''"></span>
      <span :class="showLeftNav ? '' : 'active'"></span>
    </a>

    <modal
      :show="showConfirmReconnectEmail"
      @close="showConfirmReconnectEmail = false"
    >
      <h3 slot="header">Reconnection required</h3>
      <p>
        It looks like your email account{{
          userEmail ? ` ${userEmail.address}` : ""
        }}
        got disconnected! Do you want to reconnect it?
      </p>
      <div slot="footer" class="d-flex">
        <base-button type="primary" @click="reconnectEmail">Yes</base-button>
        <base-button type="danger" @click="showConfirmReconnectEmail = false"
          >No</base-button
        >
      </div>
    </modal>

    <InboxSendEmailPanel
      :show="showInboxSendEmail"
      @close="showInboxSendEmail = false"
      @invalidEmailToken="openEmailReconnectionModal"
      :campaignId="lead ? lead.campaign_id : null"
    />
  </div>
</template>
<script>
/* eslint-disable no-console */
import { mapState, mapGetters } from "vuex";

import {
  MESSAGES,
  AVAILABLE_INTEGRATIONS,
  AVAILABLE_EMAILS
} from "@/constants";

import Configs from "../configs";
import { getPermissionErrorMessage } from "@/utils";

/* components */
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import Multiselect from "vue-multiselect";
import ResSplitPane from "vue-resize-split-pane";
const InboxFilter = () => import("../components/InboxFilter");
const InboxSendEmailPanel = () => import("../components/InboxSendEmailPanel");
import VueDocPreview from "vue-doc-preview";
import Avatar from "vue-avatar";

export default {
  components: {
    InboxFilter,
    InboxSendEmailPanel,
    "rs-panes": ResSplitPane,
    Avatar,
    quillEditor,
    VueDocPreview,
    Multiselect
  },

  data() {
    return {
      filterbarBackground: "vue", //vue|blue|orange|green|red|primary
      getPermissionErrorMessage: getPermissionErrorMessage,
      availableIntegrations: AVAILABLE_INTEGRATIONS,
      availableEmails: AVAILABLE_EMAILS,
      initialQuery: {},
      allLeadEmails: [],
      leadId: null,
      //-- Top filtter emails
      leadEmail: null,
      userEmail: null,
      threadsCurrentEmailAccount: null,
      //--
      threadId: null,
      collapse: [],
      content: "",
      showInboxSendEmail: false,

      to: [],
      cc: [],
      subject: "",
      toOptions: [],
      ccOptions: [],
      isReply: false,
      replyToMessage: null,

      loading: {
        userEmails: false,
        send: false
      },
      showLeftNav: false,
      showConfirmReconnectEmail: false,

      firstPaneWidth: 300,
      allowResize: true,
      isShallow: false,
      isFirstPaneFocused: true,

      resizeHandler: null,
      keyupHandler: null
    };
  },
  computed: {
    ...mapState({
      campaign: (state) => state.inbox.currentCampaign,
      stage: (state) => state.inbox.currentStage,
      status: (state) => state.inbox.currentStatus,
      currentEmailAccount: (state) => state.inbox.currentEmailAccount,
      hasEmail: (state) => state.inbox.hasEmail,
      lead: (state) => state.inbox.currentLead,
      selectedThread: (state) => state.inbox.selectedThread,
      isThreadSelected: (state) => state.inbox.selectedThread != null,
      threads: (state) => state.inbox.messages,
      emailThreads: (state) => state.inbox.emailThreads,
      alert: (state) => state.alert,
      showAlert: (state) => state.alert.showAlert,
      allUserEmails: (state) => state.integration.emails,
      allEmailAccounts: (state) => {
        return [{ email: "Any" }, ...state.integration.emailAccounts];
      },
      fromOptions: (state) => {
        if (!state.integration.emails) return [];
        return [
          ...(state.integration.emails.outlook
            ? state.integration.emails.outlook.map((e) => {
                return { address: e.address, name: "outlook" };
              })
            : []),
          ...(state.integration.emails.gmail
            ? state.integration.emails.gmail.map((e) => {
                return { address: e.address, name: "gmail" };
              })
            : [])
        ];
      }
    }),
    ...mapGetters({
      hasFullAccess: "user/hasFullAccess",
      hasViewAccess: "user/hasViewAccess"
    }),
    editor() {
      return this.$refs.threadQuillEditor.quill;
    }
  },
  watch: {
    showAlert: function (show) {
      if (!show) return;
      if (
        this.alert.content === MESSAGES["user__no-credits"] ||
        this.alert.content === MESSAGES["user__no-credits-after-free-quota"]
      ) {
        this.showBillingPackage = true;
      }
    },
    campaign() {
      this.setURL();
    },
    stage() {
      this.setURL();
    },
    status() {
      this.setURL();
    },
    hasEmail() {
      this.setURL();
    },
    async lead(lead) {
      console.log("Lead selected:", lead);

      this.allLeadEmails =
        lead && lead.data && lead.data.emails && lead.data.emails.length
          ? lead.data.emails
          : [];

      await this.getMessages();

      this.setURL();
    },
    allLeadEmails(emails) {
      const isEmailsArray = typeof emails != "string";

      if (emails && isEmailsArray && emails.length) {
        const index = emails.indexOf(this.initialQuery.lead_email);
        this.leadEmail = emails[index > -1 ? index : 0];

        this.toOptions = emails.map((e) => {
          return { address: e, name: e };
        });
        this.ccOptions = emails
          .filter((e) => e !== this.leadEmail)
          .map((e) => {
            return { address: e, name: e };
          });
      } else {
        this.leadEmail = null;
        this.toOptions = [];
        this.ccOptions = [];
      }
    },
    allEmailAccounts(val) {
      if (val && val.length) {
        const index = val
          .map((e) => e.id + "")
          .indexOf(this.initialQuery.campaign);
        this.threadsCurrentEmailAccount = val[index === -1 ? 0 : index];
      }
    },
    allUserEmails(item) {
      if (item) {
        if (this.initialQuery.user_email) {
          Object.keys(item).forEach((emailId) => {
            if (!item[emailId]) return;
            item[emailId].forEach((email) => {
              if (email.address === this.initialQuery.user_email) {
                this.userEmail = {
                  name: emailId,
                  address: email.address
                };
              }
            });
          });
        } else {
          const selectedEmailId =
            item.gmail && item.gmail.length
              ? AVAILABLE_EMAILS.gmail.id
              : AVAILABLE_EMAILS.outlook.id;
          this.userEmail = item[selectedEmailId]
            ? {
                ...(this.userEmail || {}),
                ...item[selectedEmailId][0],
                name: selectedEmailId
              }
            : null;
        }
      }
    },
    userEmail() {
      if (this.leadEmail) {
        this.getMessages();
      }
      this.setURL();
    },
    threadsCurrentEmailAccount() {
      if (this.leadEmail) {
        this.getMessages();
      }

      this.setURL();
    },
    leadEmail(val) {
      this.to = [];
      if (val) {
        this.to = [{ address: val, name: val }];
      }
      this.setURL();
    },
    emailThreads(val) {
      this.collapse = [];
      if (val && val.length > 0) {
        const index = val.findIndex((s) => s.id === this.initialQuery.thread);
        const thread = val[index];

        this.selectThread(thread);
      } else {
        this.selectThread(null);
      }

      this.resizeWindow();
      this.focusPane(true);
    },
    threadId(val) {
      console.log("Select threadId", val);

      this.collapse = [];
      this.isReply = val ? true : false;
      this.setURL();
    }
  },

  async mounted() {
    // to reload userpilot
    this.$store.dispatch("user/setRoute", this.$route.path);

    document.querySelector("#inbox");
    if (!this.keyupHandler) {
      this.keyupHandler = window.addEventListener("keyup", this.keyup);
    }
    if (!this.resizeHandler) {
      this.resizeHandler = window.addEventListener("resize", this.resizeWindow);
    }
    this.$filterbar.displayFilterbar(false);

    setTimeout(() => {
      this.editor.root.setAttribute("data-placeholder", "");
      if (document.querySelector(".inbox .Pane button.ql-image")) {
        document.querySelector(".inbox .Pane button.ql-image").style.display =
          "none";
        document.querySelector(".inbox .Pane button.ql-video").style.display =
          "none";
      }
      this.resizeWindow();
    }, 100);

    document.ontouchmove = function () {
      return true;
    };
    this.initialQuery = { ...this.$route.query };

    console.log("Inbox initial query:", this.initialQuery);

    await this.getConnectedEmails();
  },
  beforeDestroy() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeWindow);
      this.resizeHandler = null;
    }
  },
  methods: {
    setURL() {
      this.$router.replace({
        name: "inbox",
        query: {
          thread: this.threadId ? this.threadId : undefined,
          user_email:
            this.threadsCurrentEmailAccount &&
            this.threadsCurrentEmailAccount.type
              ? this.threadsCurrentEmailAccount.email
              : undefined,
          lead_email: this.leadEmail ? this.leadEmail : undefined,
          lead: this.lead && this.lead.id ? this.lead.id : undefined,
          status: this.status && this.status.id ? this.status.id : undefined,
          stage: this.stage && this.stage.id ? this.stage.id : undefined,
          has_emails: this.hasEmail ? true : undefined,
          campaign:
            this.campaign && this.campaign.id ? this.campaign.id : undefined
        }
      });
      // to reload userpilot
      this.$store.dispatch("user/setRoute", this.$route.path);
      this.resizeWindow();
    },
    hideFilterbar() {
      if (this.$filterbar.showFilterbar) {
        this.$filterbar.displayFilterbar(false);
      }
    },
    showFilterbar() {
      setTimeout(() => {
        this.$filterbar.displayFilterbar(true);
      }, 0);
    },
    toggleLeftNavbar() {
      this.$filterbar.displayFilterbar(false);
      this.showLeftNav = !this.showLeftNav;
      setTimeout(() => {
        this.$filterbar.displayFilterbar(true);
      }, 300);
    },
    focusPane(isFirstPaneFocused) {
      this.isFirstPaneFocused = isFirstPaneFocused;

      if (!this.$refs.emailContent) return;

      const contentWidth = this.$refs.emailContent.clientWidth;
      this.isShallow = contentWidth < 700 ? true : false;
      if (this.isShallow) {
        this.firstPaneWidth = isFirstPaneFocused ? contentWidth - 40 : 40;
        this.allowResize = false;
      } else {
        this.firstPaneWidth = this.emailThreads.length ? contentWidth / 3 : 0;
        this.allowResize = this.emailThreads.length ? true : false;
      }
    },
    resizeWindow() {
      this.focusPane(this.isFirstPaneFocused);
    },
    openInbox(index) {
      const win = window.open(
        this.threads[this.threadId][index ? index : 0].web_link
      );
      if (win == null || typeof win == "undefined") {
        // Turn off the pop-up blocker
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["user__email_popup-failed"],
          alertClass: "danger"
        });
      } else {
        win.focus();
      }
    },
    changeUserEmail(email, id) {
      console.log("changeUserEmail user email", this.userEmail);
      console.log("changeUserEmail email ", email);
      console.log("changeUserEmail ", id);
      this.userEmail = {
        ...this.userEmail,
        ...email,
        name: id
      };

      console.log("changeUserEmail changed", this.userEmail);
    },
    changeUserEmailNew(emailAccount) {
      this.threadsCurrentEmailAccount = emailAccount;
    },
    async getMessages() {
      this.$set(this.loading, "messages", true);
      this.threadId = null;

      try {
        await this.$store.dispatch("inbox/getMessages", {
          lead_id: this.lead.id,
          lead_email: this.leadEmail,
          user_email:
            this.threadsCurrentEmailAccount &&
            this.threadsCurrentEmailAccount.type
              ? this.threadsCurrentEmailAccount.email
              : null
        });
        this.$set(this.loading, "messages", false);

        this.focusPane(this.emailThreads.length > 0);
        // this.markReadLead();
      } catch (error) {
        console.log("getMessages error", error);
        this.$set(this.loading, "messages", false);
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    hasUnread(thread) {
      let unreads = 0;
      thread.emailMessages.forEach((conversation) => {
        unreads += conversation.unread ? 1 : 0;
      });
      return unreads ? true : false;
    },
    async markReadThread(threadId) {
      if (threadId && this.lead) {
        try {
          const { still_unread } = await this.$store.dispatch(
            "inbox/markReadThread",
            {
              campaign_id: this.lead.campaign_id,
              lead_id: this.lead.id,
              thread_id: threadId
            }
          );
          if (still_unread === 0) {
            await this.$store.dispatch("inbox/markReadLead", this.lead);
          }
          setTimeout(() => {
            this.$store.dispatch("inbox/getUnreads");
          }, 1000);
        } catch (err) {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    async markReadMessage(index) {
      if (
        this.threads &&
        this.threads[this.threadId] &&
        this.threads[this.threadId][index]
      ) {
        try {
          const result = await this.$store.dispatch("inbox/markReadMessage", {
            thread_id: this.threadId,
            message_id: this.threads[this.threadId][index].id
          });
          if (result) {
            /* update leads list */
            this.$store.dispatch("inbox/markReadLead", this.lead);
            /* refresh unread status for nav */
            setTimeout(() => {
              this.$store.dispatch("inbox/getUnreads");
            }, 1000);
          }
        } catch (err) {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    async getConnectedEmails() {
      this.loading.userEmails = true;
      try {
        await this.$store.dispatch("integration/getConnectedEmails");
        this.loading.userEmails = false;
      } catch (error) {
        this.loading.userEmails = false;
        if (error.response.status === 409) {
          this.userEmail = error.response.data
            ? error.response.data.metadata
            : null;
          this.showConfirmReconnectEmail = true;
        } else {
          if (!this.showAlert) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["common-500"],
              alertClass: "danger"
            });
          }
        }
      }
    },
    openEmailReconnectionModal(email) {
      this.selectedEmail = email;
      this.showConfirmReconnectEmail = true;
    },
    async reconnectEmail() {
      if (!this.hasFullAccess("integrations")) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: this.getPermissionErrorMessage("integrations:full"),
          alertClass: "danger"
        });
        return;
      }
      this.showConfirmReconnectEmail = false;
      this.loading.request = true;
      try {
        const data = await this.$store.dispatch(
          "integration/requestEmail",
          this.userEmail
        );
        this.loading.request = false;
        this.redirectToSite(data);
      } catch {
        this.loading.request = false;
        if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    showReply(conversation) {
      return (
        (conversation.from
          ? conversation.from.address
          : conversation.from_address) !== this.userEmail.address ||
        (conversation.sender
          ? conversation.sender.address
          : conversation.sender_address) !== this.userEmail.address
      );
    },
    refreshCCoptions() {
      setTimeout(() => {
        this.ccOptions = this.allLeadEmails
          .filter((email) => {
            const index = this.to.map((e) => e.address).indexOf(email);
            return index === -1;
          })
          .map((e) => {
            return { address: e, name: e };
          });
        console.log(this.allLeadEmails, this.ccOptions);
      }, 100);
    },
    selectToEmail(e) {
      const cIndex = this.cc.map((e) => e.address).indexOf(e.address);
      if (cIndex > -1) {
        this.cc.splice(cIndex, 1);
      }
      this.refreshCCoptions();
    },
    addTOOption(newEmail) {
      this.toOptions.push({ address: newEmail, name: newEmail });
      this.to.push({ address: newEmail, name: newEmail });
    },
    addCCOption(newEmail) {
      this.ccOptions.push({ address: newEmail, name: newEmail });
      this.cc.push({ address: newEmail, name: newEmail });
    },
    startSending(replyToMessage) {
      if (!replyToMessage) {
        this.selectThread(null);
      } else {
        this.replyToMessage = replyToMessage;
      }
      // this.showInboxSendEmail = true;
    },
    async sendDirectly() {
      let lastMessage, firstMessage;

      if (this.selectedThread) {
        console.log("It is reply");
        firstMessage = this.selectedThread.emailMessages[0];
        lastMessage =
          this.selectedThread.emailMessages[
            this.selectedThread.emailMessages.length - 1
          ];
      } else {
        console.log("It is a new conversation");
      }

      if (!this.content) {
        this.$store.dispatch("setAlert", {
          showAlert: true,
          content: MESSAGES["email__content-empty"],
          alertClass: "danger"
        });
        return;
      }

      this.$set(this.loading, "send", true);
      try {
        let result;
        if (this.selectedThread) {
          const replyMessageId = this.replyToMessage
            ? this.replyToMessage.id
            : lastMessage.id;
          const replyMessageSubject = this.replyToMessage
            ? this.replyToMessage.subject
            : lastMessage.subject;

          // Thread is selected and it is reply in the thread
          //console.log("inbox/replyEmail", {
          result = await this.$store.dispatch("inbox/replyEmail", {
            name: firstMessage.source,
            address: firstMessage.from_address,
            message_id: replyMessageId,
            lead_id: this.lead.id,
            reply_meta: {
              subject: "Re: " + replyMessageSubject,
              body: this.content,
              to_recipients: firstMessage.to_recipients,
              cc_recipients: firstMessage.cc_recipients
            }
          });
        } else {
          // Thread is not selected and it is a new conversation
          result = await this.$store.dispatch("inbox/sendEmail", {
            name: this.userEmail.name,
            address: this.userEmail.address,
            lead_id: this.lead.id,
            send_meta: {
              subject: this.subject,
              body: this.content,
              to_recipients: this.to,
              cc_recipients: this.cc
            }
          });
        }
        this.$set(this.loading, "send", false);

        // Clean up the form
        this.subject = "";
        this.content = "";

        // update threads list
        if (result && result.data && result.data.sent && result.data.message) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["email__send_message-success"],
            alertClass: "success"
          });
          this.$store.dispatch("inbox/setMessage", {
            message: result.data.message
          });
          this.$nextTick(() => {
            var threadId = result.data.message.thread_id;

            this.selectThreadById(threadId);
          });
        }
      } catch (error) {
        this.$set(this.loading, "send", false);
        if (
          error.response.status === 409 &&
          error.response.data.hasOwnProperty("user_message_id")
        ) {
          const email = error.response.data
            ? error.response.data.metadata
            : null;
          const messageId = error.response.data.user_message_id;

          if (messageId && messageId[1] === "email__invalid-auth") {
            this.userEmail = email;
            this.showConfirmReconnectEmail = true;
          } else if (!this.showAlert) {
            const message = (
              MESSAGES[messageId && messageId[1]] || MESSAGES["common-500"]
            ).replace(
              "{{email_provider}}",
              email && email.name
                ? AVAILABLE_EMAILS[email.name].name
                : "The email provider"
            );
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: message,
              alertClass: "danger"
            });
          }
        } else if (
          error.response.status === 403 &&
          error.response.data["status_name"] === "email__limit_exceeded"
          ) {
            this.$store.dispatch("setAlert", {
              showAlert: true,
              content: MESSAGES["email__limit_exceeded"],
              alertClass: "danger"
            });
        } else if (!this.showAlert) {
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["common-500"],
            alertClass: "danger"
          });
        }
      }
    },
    redirectToSite(data) {
      const { name, nonce } = data;
      if (name === "outlook") {
        const scopes = [
          "offline_access",
          "https://graph.microsoft.com/Mail.ReadWrite",
          "https://graph.microsoft.com/Mail.Send",
          "https://graph.microsoft.com/User.Read"
        ].join(" ");
        const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${Configs.services.outlook.client_id}&response_type=code&redirect_uri=${Configs.site.url}/api/webhooks/outlook/connect&response_mode=form_post&scope=${scopes}&state=${nonce}`;
        const win = window.open(url, "_blank");
        if (win == null || typeof win == "undefined") {
          // Turn off the pop-up blocker
          this.$store.dispatch("setAlert", {
            showAlert: true,
            content: MESSAGES["user__email_popup-failed"],
            alertClass: "danger"
          });
        } else {
          win.focus();
        }
      }
    },
    selectThreadById(threadId) {
      const index = this.emailThreads.findIndex((s) => s.id === threadId);
      this.selectThread(this.emailThreads[index]);
    },
    selectThread(thread) {
      if (thread) {
        this.threadId = thread.id;
        this.focusPane(false);
        this.markReadThread(thread);
      } else {
        this.threadId = null;
      }

      this.replyToMessage = null;

      this.$store.dispatch("inbox/selectThread", thread);
    },
    getImageByEmailAddress(email) {
      const index = this.allLeadEmails.indexOf(email);
      if (index > -1) {
        return this.lead.profile_pic;
      }
      return null;
    },
    toggleCollapse(index, e) {
      if (e) {
        let hasAction = false;
        const path = e.path || (e.composedPath && e.composedPath());
        path.forEach((element) => {
          if (
            element.tagName === "A" ||
            element.tagName === "UL" ||
            element.tagName === "BUTTON"
          ) {
            hasAction = true;
          }
        });
        if (hasAction) return;
      }
      this.collapse[index] = !this.collapse[index];
      this.collapse = [...this.collapse];
      // this.markReadMessage(index);
      //this.resizeWindow();
    },
    removeImage(index) {
      if (!this.selectedThread && !this.selectedThread.emailMessages[index])
        return;

      let doc = document.createElement("div");
      let isFirst = true;
      doc.innerHTML = this.selectedThread.emailMessages[index].body;
      doc.querySelectorAll("img").forEach((img) => {
        const span = document.createElement("span");
        if (isFirst) {
          if (this.threads[this.threadId][index].web_link) {
            const a = document.createElement("a");
            const span1 = document.createElement("span");
            const span2 = document.createElement("span");
            span1.innerHTML = "Click ";
            span2.innerHTML = " to view this email in your inbox ";
            a.href = this.threads[this.threadId][index].web_link;
            a.innerHTML = "here";
            span.appendChild(span1);
            span.appendChild(a);
            span.appendChild(span2);
          } else {
            span.innerHTML =
              "This email contains image attachments, which are not supported by Ninja Influence right now.";
          }
          span.setAttribute("style", "color: red; font-size: 0.8rem;");
          isFirst = false;
        }
        img.parentNode.replaceChild(span, img);
      });
      return doc.innerHTML;
    }
  }
};
</script>

<style lang="scss">
@import "../assets/scss/pages/inbox";
</style>
